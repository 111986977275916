import { WooBox } from 'components/WooBox';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { Container } from 'components/Container';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const ShareCheckerContest: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />
    <Container manageLayout={false}>
      <WooBox />
    </Container>
  </Layout>
);

export default ShareCheckerContest;
